@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  cursor: pointer;
  
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  -webkit-tap-highlight-color: transparent;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  position: relative;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }


}


.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
  gap: 3px;
    left: 20px;
    bottom: 20px;
    color: #0b0b0b;
    display: flex;
    padding: 4px 5px 2px;
    position: absolute;
    align-items: baseline;
    background-color: #eef2f5;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    & div {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      gap: 4px;
    }
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #000;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.peopleLocation {
  gap: 18px;
  display: flex;
  margin: 10px 0;
  align-items: center;

  & .iconHeading {
    gap: 5px;
    display: flex;
    align-items: center;

    & .locationIcon {
      line-height: normal;

      & svg {
        width: 18px;
        height: 18px;

        & path {
          fill: var(--marketplaceColor);
        }
      }
    }
  }
}

.favIcon {

  /* z-index: 99; */
  & svg {
    width: 25px;
    height: 25px;

    @media(max-width: 768px) {
      width: 23px;
      height: 23px;
    }
  }

  & .dislike {
    & svg {
      & path {
        stroke: var(--marketplaceColor);
      }
    }
  }
}

.rowReview {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cityName {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #878787;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reviewsBox {
  gap: 5px;
  display: flex;

  & .starIcon {
    & svg {
      margin-top: -3px;
    }

    & .fillStar {
      & svg {
        & path {
          fill: var(--marketplaceColor);
        }
      }
    }
  }
}


.sliderWrapper {

  /* & :global(.swiper) {
    width: 100%;
    height: 100%;
  }

  & :global(.swiper-button-prev:after) {
    width: 25px;
    height: 25px;
    background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.453%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eleft%3C%2Ftitle%3E%3Cpath%20d%3D%22m8.031%202%201.063%201.063%20-5.094%205.125%205.094%205.125%20-1.063%201.063L1.906%208.188%208.031%202Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  }

  & :global(.swiper-button-next:after) {
    width: 25px;
    height: 25px;
    background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%22-2.406%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eright%3C%2Ftitle%3E%3Cpath%20d%3D%22m3.063%2014.375%20-1.063%20-1.063%205.094%20-5.125L2%203.063l1.063%20-1.063%206.125%206.188L3.063%2014.375Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E")
  }

  & :global(.swiper-button-disabled) {
    display: none;
  } */
  &>div {
    &>div {
      &:nth-child(1) {
        left: 14px;
        z-index: 1;
      }

      &:nth-child(3) {
        right: 20px;
        z-index: 1;
      }
    }
  }

  & :global(.slick-prev:before) {
    display: none;
  }

  & :global(.slick-next:before) {
    display: none;
  }
}

.cardBottonBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.ListingCard_tag {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 20px;
  padding: 2px 17px;
  color: black;
  font-size: 14px;
}